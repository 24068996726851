import React, { useEffect } from 'react'
import { Footer } from './footer'
import { Header } from './header'

interface Props {
    children?: any;
}

export function MainWrapper({ children }: Props) {

    const scrollDiv: any = React.createRef();

    useEffect(() => {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    }, [scrollDiv])

    return (
        <div ref={scrollDiv} style={{ width: '100%' }}>
            <Header />
            {children}
            <Footer />
        </div>
    )
}