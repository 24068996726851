import React from 'react'
import { appendClass } from '../../utils'


export interface ListItemProps {
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    left?: React.ReactNode;
    right?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    leftStyle?: React.CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    disabled?: boolean;
    checkbox?: boolean;
    checked?: boolean;
}

function ListItem({ subtitle, title, right, left, containerStyle, leftStyle, className, onClick, disabled, checkbox, checked }: ListItemProps) {

    if (typeof title === "string") {
        title = <div className='li-title-text'>{title}</div>
    }

    if (typeof subtitle === "string") {
        subtitle = <div className='li-subtitle-text'>{subtitle}</div>
    }

    if(disabled){
        containerStyle = {...containerStyle, opacity: 0.3}
    }

    if(onClick){
        className = className?.concat(" clickable")
    }

    return (
        <div onClick={!disabled ? onClick : undefined} style={containerStyle} className={appendClass('list-item', className)}>
            <div className='flex vertical'>
                {left ? <div style={leftStyle} className='list-left'>{left}</div> : null}
                {
                    title || subtitle ?
                        <div className='list-content'>
                            <div className='li-title'>{title}</div>
                            <div className='li-subtitle'>{subtitle}</div>
                        </div>
                        : null
                }
            </div>
            {right ? <div className='list-right'>{right}</div> : null}
        </div>
    )
}

export default ListItem
