import React from 'react'
import Loading, { LoadingTint } from '../loading';
import { appendClass } from '../../utils';
import Click, { ClickProps } from './Click';

interface ButtonProps extends ClickProps {
    loading?: boolean;
    loadingTint?: LoadingTint;
    fancy?: boolean;
}

export const Button = ({ className, loading, loadingTint = "light", fancy, ...props }: ButtonProps) => {
    if (fancy) {
        className = appendClass("btn-icon", className)
    }
    return (
        <Click disabled={loading} icon={loading ? <Loading size={20} tint={loadingTint} /> : null} className={appendClass("button", className)} {...props} />
    )
}

export default Button