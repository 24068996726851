import React from 'react'


export type LoadingTint = "light" | "dark";

interface Props {
    size?: string | number;
    tint?: LoadingTint
}

function Loading({ size }: Props) {
    return (
        <div>Loading</div>
    )
}

export default Loading