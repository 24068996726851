import React from 'react'
import { IconUser2 } from '../icons';


interface Props {
    url?: string;
    icon?: React.ReactNode;
    title?: string;
    size?: number;
}

function Avatar({ icon = <IconUser2 />, size = 45, title, url }: Props) {

    if(title){
        icon = null
    }

    return (
        <div style={{ height: size, width: size }} className='avatar'>
            <div className='av-image'>
                <img src={url} alt="" />
            </div>
            <div className='av-placeholder'>
                {icon ? <div className='av-icon'>{icon}</div> : null}
                {title ? <div style={{fontSize: ((size / 3)) + "px"}} className='av-title'>{title}</div> : null}
            </div>
        </div>
    )
}

export default Avatar
