import React from 'react'

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    type?: "text" | "password";
    placeholder?: string;
    label?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    padding?: number | string;
    fontType?: "bold" | "sans";

}

function Input({ type, placeholder = "Type here", label, left, right, padding, fontType, ...props }: InputProps) {
    return (
        <div className='field'>
            {label ? <div className='form-label'>{label}</div> : null}
            <div className='input-container'>
                {left ? <div className='input-left'>{left}</div> : null}
                <input style={{ padding, fontFamily: fontType }} type={type} placeholder={placeholder} {...props} />
                {right ? <div className='input-right'>{right}</div> : null}
            </div>
        </div>
    )
}

export default Input
